<!--
 * @Description: 调度员管理
 * @Author: ChenXueLin
 * @Date: 2021-09-09 15:24:57
 * @LastEditTime: 2022-07-15 16:08:41
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userId">
              <el-input
                v-model="searchForm.userId"
                placeholder="用户ID"
                title="用户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeName">
              <el-input
                v-model="searchForm.employeeName"
                placeholder="调度员名称"
                title="调度员名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneNo">
              <el-input
                v-model="searchForm.phoneNo"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleId">
              <e6-vr-select
                v-model="searchForm.roleId"
                :data="userRoleList"
                placeholder="用户角色"
                title="用户角色"
                clearable
                :props="{
                  id: 'id',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userStatus">
              <e6-vr-select
                v-model="searchForm.userStatus"
                :data="userStatus"
                placeholder="用户状态"
                title="用户状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeNo">
              <el-input
                v-model="searchForm.employeeNo"
                placeholder="工号"
                title="工号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByUserName">
              <el-input
                v-model="searchForm.createByUserName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getDispatcherList, getUserRoleList } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "dispatcherManage",
  components: {},
  data() {
    return {
      total: 0,
      loading: false,
      roleId: [2], //客服组id
      userRoleList: [], //角色下拉框
      userStatus: [
        {
          id: 1,
          label: "启用"
        },
        {
          id: 0,
          label: "停用"
        }
      ],
      searchForm: {
        createByUserName: "", //创建人
        employeeName: "", //调度员名字
        phoneNo: "", //电话
        roleId: "",
        createTime: [],
        startCreatedTime: "",
        endCreatedTime: "",
        userId: "",
        userStatus: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户id",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "employeeNo",
          display: true,
          fieldLabel: "工号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeName",
          display: true,
          fieldLabel: "调度员名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNo",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleNameList",
          display: true,
          fieldLabel: "用户角色",
          width: 220,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userStatusName",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaNameList",
          display: true,
          fieldLabel: "负责版块",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByUserName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [getUserRoleList(this.roleId)];
        let [roleRes] = await Promise.all(promiseList);
        //角色下拉框
        this.userRoleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询全部工程师
    async queryList() {
      try {
        this.loading = true;
        let res = await getDispatcherList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.roleNameList = item.roleNameList.join(",");
          item.areaNameList = item.areaNameList.join(",");
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 操作--更多
    handleOperate(val) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/userDetail",
          params: {
            refresh: true
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
